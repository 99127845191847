import { useEffect, useState } from 'react'
import './App.css'
import { getPractices, validateEmail } from './action';
import NotFound from './NotFound';
import { API, CODE_CONFIRM_API, WEBVOTE_API } from './action/config';

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramValue = urlParams.get('astroId');

  const [dataSpec, setDataSpec] = useState([]);
  const [codeConfirm, setCodeConfirm] = useState('');

  const [errorEmail, setErrorEmail] = useState('');
  const [errorCodeConfirm, setErrorCodeConfirm] = useState('');

  const [visibleStatus, setVisibleStatus] = useState('vote')
  const [load, setLoad] = useState(false)
  const [data, setData] = useState({
    owner: '',
    to: paramValue,
    practic: dataSpec[0]?.id || null,
  })

  useEffect(() => {
    getPractices(setDataSpec);
  }, [])
  useEffect(() => {
    setData(s => {
      return { ...s, practic: dataSpec[0]?.id }
    })
  }, [dataSpec])

  const sendVote = async (e) => {
    e.preventDefault();
    setLoad(true)
    setErrorEmail('')
    if (validateEmail(data.owner)) {
      try {
        const response = await fetch(API + WEBVOTE_API, {
          method: 'POST',
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        })
        if (response.status === 409) {
          const resdata = await response.json();
          setErrorEmail(resdata.message)
        }
        if (response.status === 200) {
          const resdata = await response.json();
          setVisibleStatus('confirm')
        }
      } catch (error) {
      }
      setLoad(false)
    } else {
      setErrorEmail('Проверьте правильность почты.');
      setLoad(false);
    }
  }

  const sendCodeConfirm = async (e) => {
    e.preventDefault();
    setLoad(true)
    setErrorCodeConfirm('')
    try {
      const response = await fetch(API + WEBVOTE_API + '/' + CODE_CONFIRM_API, {
        method: 'POST',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.owner,
          codeConfirm: codeConfirm,
        })
      })
      if (response.status === 200) {
        const resdata = await response.json();
        setVisibleStatus('voted')
      }
      if (response.status === 403) {
        setErrorCodeConfirm('Не правильный код.')
      }
    } catch (error) {
    }
    setLoad(false)
  }
  if (!paramValue) {
    return <NotFound />
  }

  return (
    <div className='main'>
      {
        visibleStatus === 'vote' ?
          <form onSubmit={sendVote}>
            <div className='container'>
              <h1 className='title'>
                Голосование в рейтинге
              </h1>
              <h5 className='content'>
                Этим голосом вы подтверждаете, что <b>покупали продукт</b> астролога,
                который попросил вас проголосовать и <b>довольны совместной работой</b>.
              </h5>

              <div className={dataSpec.length === 0 ? 'cardNone' : 'card'}>
                <h4>1 шаг. Выберите направление работы</h4>
                <div className='items'>
                  {dataSpec?.map((el, i) => {
                    return (
                      <div
                        key={el.id}
                        className='item'
                        style={data.practic === el.id ? { backgroundColor: 'rgba(50, 96, 149, 0.20)' } : {}}
                        onClick={(e) => {
                          setData((s) => {
                            return { ...s, practic: el.id }
                          })
                        }}>
                        {el.practic}
                      </div>)
                  })}
                </div>
              </div>

              <div className={dataSpec.length === 0 ? 'cardNone' : 'card'}>
                <h4>2 шаг. Введите почту</h4>
                <div className='items'>
                  <input className='inputEmail'
                    placeholder='Почта'
                    type='email'
                    required
                    onChange={e => setData((s) => {
                      return { ...s, owner: e.target.value }
                    })
                    } />
                  <button className='sendBtn' type='submit' disabled={load}>{load
                    ? <div className='loaderBtn'></div>
                    : "Отдать голос"}</button>
                </div>
                {errorEmail && <span className='errorText'>{errorEmail}</span>}
              </div>
              <div className={dataSpec.length !== 0 ? 'cardNone' : 'loader'}></div>
              <p className='privacyText'>Нажимая кнопку «Отдать голос», вы принимаете условия <span style={{ color: 'white' }}>
                Политики конфиденциальности</span>
              </p>
            </div>
          </form> : visibleStatus === 'confirm' ? <form onSubmit={sendCodeConfirm}>
            <div className='container'>
              <h1 className='title'>
                Голосование в рейтинге
              </h1>
              <h5 className='content'>
                Мы отправили код подтверждения на вашу электронную почту. Пожалуйста, введите его, чтобы засчитать ваш голос.
              </h5>

              <div className='card'>
                <h4>Код подтверждения</h4>
                <div className='items'>
                  <input className='inputEmail' placeholder='код' onChange={(e)=>setCodeConfirm(e.target.value)} maxLength={4}/>
                  <button className='sendBtn' type='submit' disabled={load}>{load
                    ? <div className='loaderBtn'></div>
                    : "Отправить"}</button>
                </div>
                {errorCodeConfirm && <span className='errorText'>{errorCodeConfirm}</span>}
              </div>
            </div>
          </form>
            : visibleStatus === 'voted' ? <div className='container'>
              <h1 className='title'>
                Спасибо,<br />
                Ваш голос учтен!
              </h1>
              <h5 className='content'>
                Реклама реклама реклама реклама приложения
              </h5>
            </div>
              : <></>
      }
    </div>
  )
}

export default App